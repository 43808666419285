import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { IoEyeSharp } from "react-icons/io5";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";

//
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import Toast from "../../Hooks/useToast";
import { useGetSubscriptionDetailsQuery } from "../../services/subscriptionApi";
import { setSubscriptionList } from "../../features/subscriptionSlice";

const SubscriptionManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetSubscriptionDetailsQuery({ refetchOnMountOrArgChange: true });

  var subscriptionData = useSelector(
    (state) => state.subscription.subscriptionList
  );

  useEffect(() => {
    setRowData(subscriptionData);
  }, [subscriptionData]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(setSubscriptionList(data.data));
    }
  }, [isSuccess, data, dispatch]);

  function indication() {
    // return something here
    // return 'Table is empty';
    if (isLoading) {
      return indicationMessage;
    }
    if (!isSuccess) {
      return "Record not found.";
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "User Name",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "type",
      text: "Subscription type",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "amount",
      text: "Subscription Amount",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "status",
      text: "Subscription Status",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.subscriber_id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`d-flex justify-content-center align-items-center white-text rounded-3 mx-2 p-2 ${
                  row.status ? "bg-danger" : "bg-success"
                } `}
                role="button"
                onClick={() => {}}
              >
                <span className="text-white">
                  {row.status ? "Expired" : "On going"}
                </span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Subscription Management"} />
        </div>
        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add Event"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          // <Table data={rowData} columns={columns} indication={indication} />
          <Table data={rowData} columns={columns} indication={indication} />
        }
      </div>
    </div>
  );
};

export default SubscriptionManagementScreen;
