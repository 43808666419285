import React, { useEffect, useState } from "react";
import styles from "./styles/StaticPage.module.scss";
import { Col, Row } from "react-bootstrap";
import { SubTitle, Button, Input } from "../../Components/CustomComponents";
import TextEditor from "../../Components/TextEditor/TextEditor";
import { useSelector } from "react-redux";
import { useUpdateStaticPagesMutation } from "../../services/settingApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";

export default function PrivacyPolicy() {
  const [value, setValue] = useState(
    "<h1>Privacy Policy</h1><h2>What is Lorem Ipsum?</h2><p><br></p><p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p > <p><br></p>"
  );
  const [link, setLink] = useState("");

  const handleValueChange = (newText) => {
    setValue(newText);
    console.log("Updated text:", newText);
  };

  const handleLinkChange = (e) => {
    console.log(e.target.value);
    setLink(e.target.value);
  };

  var settingData = useSelector((state) => state.setting);

  useEffect(() => {
    setValue(settingData.privacy);
    setLink(settingData.privacy_link);
    // setLink(e.target.value);
  }, [settingData]);

  const [updatePrivacy] = useUpdateStaticPagesMutation();

  const handleSaveText = async () => {
    console.log(value);
    const data = {
      type: "PRIVACY",
      description: value,
      link: link,
    };
    console.log(data);

    try {
      const response = await updatePrivacy(data);

      if (response.data.code === 200) {
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <div className={styles.StaticPage}>
      <Row>
        <Col sm={10}>
          <SubTitle text="Privacy Policy" classes="mb-4" />
          <Input
            name="link"
            type="text"
            value={link}
            onChange={handleLinkChange}
          />
        </Col>
        <Col sm={2}>
          <Button
            classes={`${styles.cardBtn} `}
            text="Save"
            onClick={handleSaveText}
          ></Button>
        </Col>
      </Row>
      {<TextEditor text={value} onChange={setValue} />}
    </div>
  );
}
