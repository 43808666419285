import React, { useState } from 'react'
import styles from './styles/sendNotificationScreen.module.scss';
import { Button, Input, SubTitle } from '../../Components/CustomComponents';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useSentNotificationMutation } from '../../services/notificationApi';
import Toast, { SuccessToast } from '../../Hooks/useToast';

const SendNotificationScreen = ({ setPage }) => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({});

    const [sendNotification, { isLoading }] = useSentNotificationMutation();

    const handleSendNotification = async (data) => {
        try {
            const response = await sendNotification(data);

            if (response.data.code === 200) {
                SuccessToast(response?.data.message);
                reset();
            }
        } catch (error) {
            if (error?.response && error?.response?.data?.message) {
                Toast(error?.response?.data?.message, "error");
            } else {
                Toast("An error occurred during login", "error");
            }
        }
    };


    return (
        <div className={styles.MainContainer}>

            {errors
                ? errors.title?.message
                    ? Toast(errors.title?.message, "error")
                    : Toast(errors.notification?.message, "error")
                : null}

            <div className={styles.headerContainer}>

                <div>
                    <SubTitle text={"Send Notification"} />
                </div>
                <Row>
                    <Col md={12}>
                        <Button
                            classes={`${styles.cardBtn} `}
                            text="Send"
                            onClick={handleSubmit(handleSendNotification)}
                        ></Button>
                    </Col>
                </Row>
            </div>

            <div className='mt-3'>
                <Form>

                    <Controller
                        control={control}
                        name="title"
                        rules={{
                            required: "Title is required.",
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <InputGroup className="mb-3">
                                <Input
                                    placeholder="Title"
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            </InputGroup>
                        )}
                    />

                    <Controller
                        control={control}
                        name="notification"
                        rules={{
                            required: "Notification Message is required.",
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <InputGroup className="mb-3">
                                <Input
                                    placeholder="Notification"
                                    type="textarea"
                                    as="textarea"
                                    rows={5}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            </InputGroup>
                        )}
                    />

                </Form>

            </div>



        </div>
    )
}

export default SendNotificationScreen
