import React, { useEffect, useState } from 'react';
import styles from './styles/StaticPage.module.scss'
import { Col, Form, Row } from 'react-bootstrap';
import { SubTitle, Button, Input } from '../../Components/CustomComponents';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Toast, { SuccessToast } from '../../Hooks/useToast';
import { useUpdateSettingPagesMutation } from '../../services/settingApi';

const ContactUs = () => {

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onBlur'
    });

    var email = useSelector((state) => state.setting.email);

    useEffect(() => {
        if (email) {
            setValue('contact_email_us', email);
        }
    }, [email, setValue]);


    const [updateInfo] =
        useUpdateSettingPagesMutation();

    const handleDataSubmission = async (data) => {
        console.log(data);

        try {
            const response = await updateInfo(data);

            if (response.data.code === 200) {
                SuccessToast(response?.data?.message);
            }
        } catch (error) {
            if (error?.response && error?.response?.data?.message) {
                Toast(error?.response?.data?.message, "error");
            } else {
                Toast("An error occurred during login", "error");
            }
        }
    };

    return (
        <div className={styles.StaticPage}>

            {
                errors
                    ? errors.email?.message
                        ? Toast(errors.email?.message, "error")
                        : null
                    : null
            }

            <Form onSubmit={handleSubmit(handleDataSubmission)}>
                <Row>
                    <Col sm={10}>
                        <SubTitle
                            text='Contact Us'
                            classes='mb-4'
                        />
                    </Col>
                    <Col sm={2}>
                        <Button
                            classes={`${styles.cardBtn} `}
                            text="Save"
                            // onClick={handleSaveText}
                            type='submit'
                        ></Button>
                    </Col>
                </Row>
                <p className=''>Set your email, App user will contact you on this email.</p>
                <Row>
                    <Col md={12}>
                        <p className="m-0" >
                            Email
                        </p>
                        <Controller
                            control={control}
                            name="contact_email_us"
                            rules={{
                                required: "Email is required.",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Enter a valid email address.",
                                },
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Input
                                    classes="mt-2 mb-3"
                                    type="email"
                                    placeholder="Enter Admin Email"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ContactUs
