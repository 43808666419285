import React, { useState } from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Description, Input, SubTitle, Title } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { useForm, Controller } from "react-hook-form"
import { Form, InputGroup } from "react-bootstrap";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useNavigate } from 'react-router-dom'
import { WEB_ROUTES } from "../../constants";
import { useDispatch } from "react-redux";
import { useLoginUserMutation } from "../../services/userAuthApi";
import { storeToken } from "../../services/localStorageService";
import { setAuthInfo } from "../../features/authSlice";


export default function Login({ setPage }) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { handleSubmit, control, formState: { errors }, } = useForm()
    // const pagename = useSelector((state) => state.pagename.pagename);

    const handleForgotPasswordClick = () => {
        setPage(ACCOUNT_ENUM.FORGOT_PASSWORD)
    }

    const [loginUser, { isLoading }] = useLoginUserMutation();

    const handleLogin = async (data) => {
        data.device_type = "web";
        data.device_token = "123";

        try {
            const response = await loginUser(data);

            if (response.data.code === 200) {
                // dispatch(
                //     setAuthInfo({
                //         api_token: response.data.data.api_token,
                //         email: response.data.data.email,
                //         image_url: response.data.data.image_url,
                //         name: response.data.data.name,
                //         slug: response.data.data.slug,
                //     })
                // );

                storeToken(response.data.data.api_token);
                SuccessToast(response?.data?.message);
                navigate(WEB_ROUTES.DASHBOARD);
            }
        } catch (error) {
            if (error?.response && error?.response?.data?.message) {
                Toast(error?.response?.data?.message, "error");
            } else {
                Toast("An error occurred during login", "error");
            }
        }
    };

    return (
        <AccountLayout>
            {errors
                ? errors.email?.message
                    ? Toast(errors.email?.message, "error")
                    : Toast(errors.password?.message, "error")
                : null}

            <SubTitle text="Admin Panel" classes="mb-2" />

            <Form onSubmit={handleSubmit(handleLogin)}>
                <Controller
                    control={control}
                    name="email"
                    rules={{
                        required: "Email is required.",
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Enter a valid email address.",
                        },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputGroup className="mb-3"
                            style={{
                                width: '300px',
                            }}
                        >
                            <Input
                                placeholder="Email"
                                type="email"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </InputGroup>
                    )}
                />
                <Controller
                    control={control}
                    name="password"
                    rules={{
                        required: "Password is required.",
                        minLength: {
                            value: 3,
                            message: "Minimum 3 characters long.",
                        },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputGroup className="mb-3"
                            style={{
                                width: '300px'
                            }}>
                            <Input
                                type="password"
                                placeholder="Password"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </InputGroup>
                    )}
                />
                <Button
                    text={isLoading ? "Sending..." : "Login"}
                    classes="mt-3 mb-2"
                    type="submit"
                // onClick={handleSubmit}
                />
            </Form>
            <Description
                classes="ms-auto cursor-pointer"
                text="Forgot Password ?"
                onClick={handleForgotPasswordClick}
            />

        </AccountLayout>
    )
}