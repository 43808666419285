import React, { useEffect } from 'react';

import styles from "./styles/Dashboard.module.scss"

import { SubTitle } from '../../Components/CustomComponents';
import { Row, Col } from 'react-bootstrap';
import { ROUTES } from './constants';
import DashboardCard from '../../Components/Cards/DashboardCard';
import { MdPets } from "react-icons/md";
import { GiPodiumWinner } from "react-icons/gi";
import { MdEventAvailable } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";

import GlobalStyles from '../../_global.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDashboardDetailsQuery } from '../../services/dashboardApi';
import { setDashboardInfo } from '../../features/dashboardSlice';

export default function Dashboard({ setPage }) {
    const { primaryColor, secondaryColor, primaryColorWithAlpha } = GlobalStyles;

    const dispatch = useDispatch();

    const { data, isSuccess } = useGetDashboardDetailsQuery({
        refetchOnMountOrArgChange: true,
    });

    const dashboardInfo = useSelector((state) => state.dashboard);

    useEffect(() => {
        if (data && isSuccess) {
            dispatch(
                setDashboardInfo({
                    totalPost: data.data.totalpost,
                    totalWinningPost: data.data.totalwinningpost,
                    winningUser: data.data.winninguser,
                    totalEvents: data.data.totalevents,
                    totalPostLikes: data.data.totalpostlikes,
                    totalUser: data.data.totaluser,
                })
            );
        }
    }, [data, isSuccess, dispatch]);



    return (
        <div className={styles.Dashboard}>

            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <SubTitle
                        text={'Dashboard'}
                    />
                </div>
            </div>

            <Row
                className={`${styles.matchRow} w-100 mt-2 mb-5`}
            >
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={primaryColor}
                        bg2={primaryColor}
                        icon={<MdPets color={secondaryColor} />}
                        number={dashboardInfo.totalPost}
                        text={'Numbers of Pots'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={secondaryColor}
                        bg2={secondaryColor}
                        icon={<GiPodiumWinner color={secondaryColor} />}
                        number={dashboardInfo.totalWinningPost}
                        text={'Numbers of Winning Pots'} />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={primaryColor}
                        bg2={primaryColor}
                        icon={<GiPodiumWinner color={secondaryColor} />}
                        number={dashboardInfo.winningUser}
                        text={'Numbers of Winning User'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={secondaryColor}
                        bg2={secondaryColor}
                        icon={<MdEventAvailable color={secondaryColor} />}
                        number={dashboardInfo.totalEvents}
                        text={'Numbers of Events'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={primaryColor}
                        bg2={primaryColor}
                        icon={<AiFillLike color={secondaryColor} />}
                        number={dashboardInfo.totalPostLikes}
                        text={'Numbers of Liked Pots'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={secondaryColor}
                        bg2={secondaryColor}
                        icon={<FaUsers color={secondaryColor} />}
                        number={dashboardInfo.totalUser}
                        text={'Numbers of Users'}
                    />
                </Col>
            </Row>






        </div>
    )
}

