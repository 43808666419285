import React from 'react';
import { useForm, Controller } from "react-hook-form"
import { Form } from "react-bootstrap";

import styles from './styles/ChangePassword.module.scss'
import { Button, CustomInput, Description, SubTitle, UnorderedList } from '../../Components/CustomComponents';
import Toast from '../../Hooks/useToast';
import { useChangePasswordMutation } from '../../services/settingApi';

export default function ChangePassword() {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
    });

    const [changePassword, { isLoading }] =
        useChangePasswordMutation();

    const handleDataSubmission = async (data) => {
        console.log(data);
        debugger
        try {
            const response = await changePassword(data);
            console.log(response);
            if (response.data.code === 200) {
                Toast(response?.data?.message, "success");
            }
        } catch (error) {
            console.log(error);
            Toast(error?.response?.data?.message, "error");
        }
    }
    return (
        <div className={styles.ChangePassword}>
            {errors
                ? errors.current_password?.message
                    ? Toast(errors.current_password?.message, "error")
                    : errors.new_password?.message
                        ? Toast(errors.new_password?.message, "error")
                        : errors.confirm_password?.message
                            ? Toast(errors.confirm_password?.message, "error")
                            : null
                : null}
            <SubTitle
                text='Change Password'
            />
            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='fs-8 mb-4'
            />
            <Form onSubmit={handleSubmit(handleDataSubmission)}>

                <div className={styles.form}>
                    <Description
                        text='Current Password'
                        classes='mb-1'
                    />
                    <Controller
                        control={control}
                        name="current_password"
                        rules={{ required: "Current Password is required." }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <CustomInput
                                placeholder="Current Password"
                                type='password'
                                onChange={onChange}
                            />
                        )}
                    />
                    <Description
                        text='Set New Password'
                        classes='mt-3 mb-1'
                    />
                    <Controller
                        control={control}
                        name="new_password"
                        rules={{ required: "New Password is required." }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <CustomInput
                                placeholder="New Password"
                                type='password'
                                onChange={onChange}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="confirm_password"
                        rules={{ required: "Confirm Password is required." }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <CustomInput
                                placeholder="Confirm Password"
                                type='password'
                                onChange={onChange}
                            />
                        )}
                    />
                    <UnorderedList
                        lists={[
                            'At least 12 characters long but 14 or more is better.',
                            'A combination of uppercase letters, lowercase letters, numbers, and symbols.'
                        ]}
                        classes='fs-6 mt-4 mb-4'
                    />

                    <div className={styles.saveButton}>
                        <Button
                            text='Change Password'
                            classes='fs-6 mb-2 mt-auto'
                            type='submit'
                        />
                    </div>
                </div>
            </Form>

        </div >
    )
}